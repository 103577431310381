<template>
  <div ref="pdfDom" id="pdfDom" >
    <div>
      <div class="toolbar"
        style="text-align:center;font-size:20px;border-bottom:5px solid white;padding:8px;margin-top:20px"> 2024高考志愿填报
      </div>
      <!-- 工具栏 -->
      <div class="toolbar">
        <el-row type="flex" align="middle">
          <el-col :span="22">
            <span style="margin-left:10px">
              姓名： {{ userData.user_name }}
            </span>
            <span style="margin-left:10px">分数： {{ userData.score }} </span>
            <span style="margin-left:10px">位次： {{ userData.ranking }}</span>
            <span style="margin-left:20px">{{ userData.is_wenli == 1 ? '文科' : '理科' }}</span>
            <span style="margin-left:20px">
              批次：<span v-if="batch == 1">本科一批</span>
              <span v-if="batch == 2">本科二批</span>
              <span v-if="batch == 3">高职高专批</span>
            </span>
            <span style="margin-left:20px">学生电话：{{ userData.mobile }}</span>


          </el-col>
          <el-col :span="2">
            <div class="toobDiv">
              <el-button type="primary" plain size="mini" class="margin_right" @click="doPrint">导出
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 志愿表 -->
      <div class="table">
        <el-row type="flex" align="middle">
          <el-col :span="24">
            <table border v-for="(item, i) in record.college" :key="i">
              <tbody>
                <tr rowspan="7" class="th">
                  <td rowspan="7" style="width:130px; background:white">
                    <div class="px">
                      平行志愿{{ record.dg[i] }}
                    </div>
                    <div class="fc" v-if="item.tiaoji">
                      服从调剂
                    </div>
                    <div class="fc" v-else style="color:red"> 拒绝调剂 </div>

                  </td>
                  <td rowspan="7" style="width:330px; background:white">
                    <div>
                      <p><strong>院校名称：{{ item.name }} </strong></p>
                      <p>所在地：{{ item.city_name }} </p>
                      <p>招生代号：{{ item.school_id }} </p>
                      <p>办学性质：{{ item.nature }} </p>
                      <p>院校计划：{{ item.plan_num }} </p>
                      <p>录取原则：{{ item.lqgz }}</p>
                      <p v-if="item.school_tip">院校标签：{{ item.school_tip }}</p>
                    </div>
                    <div>
                      <table class="school_table">
                        <tr style="border-bottom:1px solid #dddddd">
                          <td>年份</td>
                          <td>计划数</td>
                          <td>投档分</td>
                          <td>位次</td>
                        </tr>
                        <tr style="border-bottom:1px solid #dddddd">
                          <td>2023</td>
                          <td>{{ item.td_plan_num_1 }}</td>
                          <td>{{ item.td_min_score_1 }}</td>
                          <td>{{ item.td_ranking_1 }}</td>
                        </tr>
                        <tr style="border-bottom:1px solid #dddddd">
                          <td>2022</td>
                          <td>{{ item.td_plan_num_2 }}</td>
                          <td>{{ item.td_min_score_2 }}</td>
                          <td>{{ item.td_ranking_2 }}</td>
                        </tr>
                        <tr>
                          <td>2021</td>
                          <td>{{ item.td_plan_num_3 }}</td>
                          <td>{{ item.td_min_score_3 }}</td>
                          <td>{{ item.td_ranking_3 }}</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="th" style="width:100px">专业序号</td>
                  <td class="th">专业代号/名称</td>
                  <td class="th" style="width:100px">专业备注</td>
                  <td class="th" style="width:100px">专业计划</td>
                  <td class="th" style="width:100px">学费标准</td>
                </tr>
                <tr v-for="( val, key) in record.specialty[i]" :key="key" style="height:81px">
                  <td>专业{{ word[key] }}</td>
                  <td>[{{ val.specialty_id | interpo }}]{{ val.specialty_name }}</td>
                  <td v-if="val.remarks">
                    <el-tooltip class="item" effect="dark" :content="val.remarks" placement="top">
                      <el-button size="mini">查看</el-button>
                    </el-tooltip>
                  </td>
                  <td v-else> </td>
                  <td>{{ val.plan_num }}</td>
                  <td>{{ val.tuition }}</td>
                </tr>
                <tr v-for="ddd in   5 - (record.specialty[i].length) " :key="ddd + i + 'adf'" style="height:81px">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "",
  computed: { ...mapState(['userData']) },
  data() {
    return {
      user_id: '',
      fencha: '',
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九",'十','十一','十二'],
      batchList: [
        { name: "本科一批", value: 1 },
        { name: "本科二批", value: 2 },
        { name: "高职高专批", value: 3 },
      ],
      batch: '',
      is_wenli: '',
      record: {
      }
    };
  },
  created() {
    this.user_id = this.$route.query.user_id;
    this.batch = this.$route.query.batch;
    this.is_wenli = this.$route.query.is_wenli;
    this.getUserInfo(this.user_id);

  },
  methods: {
    ...mapMutations(['setUserData', 'setOldLineScore', 'setEqualScore']),

    //下载PDF
    downLoalPdf() {
      var routeData = this.$router.resolve({
        path: "/PdfVolunteer",
        query: {
          table: JSON.stringify(this.table),
        },
      });
      window.open(routeData.href, "_blank");
    },

    doPrint() {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.htmlTitle = '志愿表';
      this.getPdf("#pdfDom");
    },

    //获取用户信息
    getUserInfo(id) {
      if (this.$route.query.nav_id) {
        this.nav_id = 2
      }
      let data = {
        id: id,
      };
      this.$fecth.post("user/getUserInfo", data).then((res) => {
        let { code, msg, data } = res;
        if (code == 200) {
          this.status = 1
          this.setUserData(data)
          this.getRecode()
          //等同分
          this.getEqualList();
          //每年录取分数线
          this.getOldCityScore();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    //获取志愿表
    getRecode() {
      let data = {
        user_id: this.user_id,
        is_wenli: this.userData.is_wenli,
        ranking: this.userData.ranking,
        score: this.userData.score,
        batch: this.batch,
      }
      this.$fecth.post("volunteer/getUserReport", data).then((res) => {
        if (res.data.record) {
          this.record = JSON.parse(res.data.record)
        } else {
          this.record = {
            id: [],
            dg: [],
            sp: [],
            college: [],
            specialty: [],
          }
        }
        console.log(this.record)

        this.$store.commit('setRecord', this.record)
      })
    },

    //根据批次往年录取分数线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLine", {
          is_wenli: this.userData.is_wenli,
          batch: this.batch,
        })
        .then((res) => {
          this.fencha = res.data[0].score;
          res.data.splice(0, 1);
          this.setOldLineScore(res.data)
        });
    },

    // 获取等同分
    getEqualList() {
      this.$fecth
        .post("score_equal/getEqualScore", {
          is_wenli: this.userData.is_wenli,
          score: this.userData.score,
        })
        .then((res) => {
          this.equalScore = res.data
          this.setEqualScore(res.data)
        });
    },

    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style lang="less">
.toobDiv {

  .el-button--mini,
  .el-button--small {
    font-size: 16px;
  }

  .el-button--mini,
  .el-button--mini.is-round {
    padding: 4px 4px;
  }
}

.dialogName {
  .content {
    padding: 40px 100px;
    visibility: middle;
    border-bottom: #ddd solid 1px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .el-dialog__header {
    border-bottom: #ddd solid 1px;
  }

  .el-dialog__body {
    padding: 0;
    font-size: 16px;
  }
}
</style>

<style scoped lang='less'>
#pdfDom {
  width: 1200px;
  margin: 0 auto;
  background: white;
  overflow: hidden;
}

.toolbar {
  background: #f4f7fc;
  padding: 20px 40px;

  .toobDiv {
    .margin_right {
      margin-left: 3px;
    }
  }
}

.table {
  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }
}

.school_table {
  border-collapse: collapse;
  border: 0 !important;
  text-align: center;

  td {
    border-bottom: 1px gray !important;
  }
}

.px {
  margin-top: 20px;
  text-align: center;
  font-size: 14;
  margin-bottom: 10px;
}

.fc {
  text-align: center;
  color: #459df6;
  margin-bottom: 10px;
}
</style>